import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer';
import Header from '@/components/header/header';
import Breadcrumb from '@/components/breadcrumb/breadcrumb';
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.min.css';
import Toolbar from '@/components/toolbar/toolbar';


export default {
    name: "product_content",
    data() {
        return {
            imageSource: require('@/assets/img/build-color/building-img-color1.png'),
            lists_color: [
                {id: 1, name_color:"gray-dark",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 2, name_color:"gray-normal",img: require('@/assets/img/Home/example_glass_02.png')},
                {id: 3, name_color:"gray-light",img: require('@/assets/img/Home/example_glass_03.png')},
                {id: 4, name_color:"white-dark",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 5, name_color:"white-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 6, name_color:"sky-light",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 7, name_color:"green-lime",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 8, name_color:"sky-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 9, name_color:"orange-light",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 10, name_color:"green-light",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 11, name_color:"yellow-light",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 12, name_color:"orange-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 13, name_color:"purple-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 14, name_color:"black-normal",img: require('@/assets/img/Home/example_glass_01.png')},
                {id: 15, name_color:"snow",img: require('@/assets/img/Home/example_glass_01.png')},
            ],
            current_color: {},
            form: {
                feature_header: [],
                feature_list_image_vector: [],
                feature_list_image_slide: [],
            },
            product: [],
            baseFront: this.$baseFront
        }
    },
    methods: {
        gotoPage(url){
            location.href = url;
        },
        gotoCompare() {
            this.$router.push({ path: '/compare', query: { compare: this.$route.params.id } })
        },
        gotoContact() {
            this.$router.push({ path: '/contact-us', query: { message: true } })
        },
        async fetchProduct() {
            await this.$axios.get("/api/show/product")
                .then((response) => {
                    if (response.data.code == 200) {
                        let product = [];
                        if(this.form.shade_glass) {
                            product = response.data.data
                            product = product.filter(i=>i.ref_type_product_id == this.form.ref_type_product_id)
                            this.product = [];
                            this.form.shade_glass.forEach(i => {
                               this.product.push(product.find(x=>x.id == i.ref_product_id))
                            });
                            this.current_color = this.product[0] ? this.product[0]:{};
                        } 
                        
                    }
                })
        },
        openLink(url) {
            console.log(url);
            window.open(url,'_blank')
        },
        async fetchSearch(id) {
            await this.$axios.get(`/api/show/type_product_content/${id}`)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.form = response.data.data[0];
                        // JSON convert string array to array object
                        this.form.feature_header = this.form.parameter_feature_header == "" ? [] : JSON.parse(this.form.parameter_feature_header)
                        this.form.feature_list_image_vector = this.form.parameter_feature_list_image_vector == "" ? [] : JSON.parse(this.form.parameter_feature_list_image_vector)
                        this.form.feature_list_image_slide = this.form.parameter_feature_list_image_slide == "" ? [] : JSON.parse(this.form.parameter_feature_list_image_slide)
                        this.form.manual_list_text = this.form.manual_list_text == "" ? [] : JSON.parse(this.form.manual_list_text)
                        this.form.manual_list_image_slide = this.form.parameter_manual_list_image_slide == "" ? [] : JSON.parse(this.form.parameter_manual_list_image_slide)
                        this.form.remark_list = this.form.remark_list == "" ? [] : JSON.parse(this.form.remark_list)
                        this.form.shade_glass = this.form.shade_glass == "" ? [] : JSON.parse(this.form.shade_glass)
                    }
                })
            console.log(this.form);
        },
    },
    watch: {
        async current_color() {
            $("#shade-image-preview").removeClass("aos-animate");
            setTimeout(function () {
            $("#shade-image-preview").addClass("aos-animate");
            }, 200);
        },
    },
    async mounted() {
        window.scrollTo(0, 0);
        await this.fetchSearch(this.$route.params.id)
        await this.fetchProduct();
        var swiper = new Swiper(".swiper", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 0,
            speed: 4000,
            pauseOnMouseEnter: false,
            parallax: true,
            autoplay: {
                delay: 1000,
                disableOnInteraction: false
            },
            breakpoints: {
                768: {
                    loop: true,
                    slidesPerView: 2,
                    spaceBetween: 20
                }
            },
        });
        document.title = "AGC - "+this.form.name;
        AOS.init();
    },
    components: {
        Footer, Header, Breadcrumb,Toolbar
    }
};